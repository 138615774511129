import { createRouter, createWebHashHistory } from 'vue-router'
import { useAuthStore } from '@/store/auth';

const routes = [
  {
    path: '/',
    component: () => import('../layouts/minimal'),
    children: [
      { path: '', component: () => import('../views/login') }
    ]
  },
  {
    path: '/',
    children: [
      { path: 'vcard/:id/:name', component: () => import('../views/card') },
    ],
    meta: { requiresAuth: false }
  },
  {
    path: '/',
    component: () => import('../layouts/default'),
    children: [
      { path: 'config', component: () => import('../views/config') },
    ],
    meta: { requiresAuth: true }
  },
  { // Always leave this as last one
    path: '/*',
    component: () => import('../views/404')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


export default router
router.beforeEach((to) => {
  const auth = useAuthStore()
  if (to.meta.requiresAuth && !auth.isLoggedIn()) return '/'
})