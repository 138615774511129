import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => ({
      // initialize state from local storage to enable user to stay logged in
      user: null,
      config: null,
      LoggedIn : false,
  }),
  actions: {
    logout() {
        this.user = null;
        this.config = null;
        this.LoggedIn = false;
    },
      init(data) {
        this.config = data;
      },
      setUser(data) {
        this.user = data;
      },
      setLoggedIn() {
        this.LoggedIn = true;
      },
      getInit() {
        return this.config;
      },
      getUserId() {
        return this.user.userid;
      },
      getUser() {
        return this.user;
      },
      isLoggedIn(){
        return this.LoggedIn;
      }
  },
  persist: {
    storage: sessionStorage, // data in sessionStorage is cleared when the page session ends.
  },
});